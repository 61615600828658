// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'
import { ActionFields }      from '@/Constants/Global/ActionFields'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Module3 }   from '@/Constants/Modules/Module3'

// Store
import Store from '@/Store/Global/Default'

export function parseDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, Module3.M30.ActionFields.Services)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idClient', '_idEquipment', '_idService', '_idStorage', '_idTechnical',
		'clientName', 'clientLastName', 'Description', 'details', 'status', 'storageCode', 'technicalLastName'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('sheet', 'Folio Solicitud').isSortable().setAlign(null, 'center')
		.add('callService', 'Número Llamada').isSortable().setAlign(null, 'center')
		.add('clientEmail', 'Solicitante').isSortable().setAlign(null, 'center').setSearchKey('client.email')
		.add('equipmentCode', 'Cód. Equipo').isSortable().setAlign(null, 'center').setSearchKey('equip.code')
		.add('equipmentName', 'Modelo Equipo').isSortable().setSearchKey('equip.name')
		.add('storageName', 'Planta').isSortable().setSearchKey('storage.name')
		.add('dateCreation', 'Fecha Solicitud').setAlign(null, 'center').setSortDirection('desc')
		.add('dateProgram', 'Fecha Reparación').setAlign(null, 'center').setSortDirection('desc')
		.add('workingDay', 'Jornada').isSortable().setAlign(null, 'center')
		.add('technicalName', 'Técnico Asignado').isSortable().setAlign(null, 'center').setSearchKey('technical.name')
		.add('statusEquipment', 'Estado Equipo').isSortable().setAlign(null, 'center').setSearchKey('dataService.statusEquipment')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'sheet', 'callService', 'clientEmail', 'equipmentCode', 'equipmentName', 'storageName', 'dateCreation', 'dateProgram', 'workingDay', 'technicalName', 'statusEquipment'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('dateProgram')	
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		callService: function(value: number) {
			if (!value) return AppValues.Strings.DEFAULT_EMPTY_STRING
			return value
		},

		dateCreation: (_: void, item: any) => {
			const date = item.dateCreation
			const _date = PrimitiveTools.Dates.parseDateString(date)
			return _date ? `${_date.date} ${_date.time}` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		dateProgram: (_: void, item: any) => {
			if (!PrimitiveTools.Dates.isValid(new Date(item.dateProgram))) return AppValues.Strings.DEFAULT_EMPTY_STRING
			const _date = PrimitiveTools.Dates.parseDateString(item.dateProgram)
			return _date ? `${_date.date} ` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		sheet: (value: number) => {
			if (value === -1) return AppValues.Strings.DEFAULT_EMPTY_STRING
			return `JANSS-${(value).toString().padStart(4, '0')}`
		},

		technicalName: (_: void, item: any) => {
			return (item.technicalName || item.technicalLastName)
				? `${item.technicalName} ${item.technicalLastName}`
				: AppValues.Strings.DEFAULT_EMPTY_STRING;
		},

		workingDay: (v: string) => {
			return v || AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}