// Helpers
import { BaseInput } from '@/Helpers/Components/DataForm/BaseInput'

// Class Export
export class InputTextArea extends BaseInput {
	private _rows: number = 3

	constructor(key: string, label: string) {
		super()
		this.Key   = key
		this.Label = label
		this.setParamsState(this.resolveInputState)
		this.setParamsType('textarea')
	}

	public setRows(rows: number): this {
		this._rows = rows
		return this
	}

	public getRows(): number {
		return this._rows
	}

	public resolveInputState(self: any) {
		let aux = self.value.trim()
		if (aux === '') return null
		if (aux.length < 3 || aux.length > 120) return false
		return true
	}
}