// Classes
import { ActionField } from '@/Classes/Records/ActionField'

// Dependencies
import Joi from 'joi'

// Namespace Export
export namespace Module3 {
	export namespace M30 {
		const TempColorStates: { [key: string]: string } = {
			Abierto:    'blue',
			Rechazado:  'red',
			'En Curso': 'orange',
			Finalizado: 'green',
		}

		export namespace ActionFields {
			const TempActionFieldStatus = new ActionField('mStatus', 'Estado de solicitud')
				.addItem('state', 'button')
				.setColor('status', TempColorStates)
				.setPermission('STATUS_BUTTON')
				.setTextFrom('status')
			;
	
			export const Services = [
				TempActionFieldStatus,
				new ActionField('actions', 'Acciones')
					.addItem('edit', 'icon')
						.setIcon('pen')
						.setPermission('ACTION_EDIT')
						.setTitle('Editar Servicio')
						.setVariant('green')
			]
		}

		export namespace Defaults {
			export enum ServiceStatus {
				ACCEPTED = 'En Curso',
				REJECTED = 'Rechazado'
			}
		}

		export namespace JoiSchemas {
			const JoiSchemaLabels = {
				_idClient:         'Cliente',
				_idEquipment:      'Equipo',
				_idService:        'Servicio',
				_idStorage:        'Almacenamiento',
				_idTechnical:      'Técnico',
				clientName:        'Nombre Cliente',
				Description:       'Descripción',
				clientLastName:    'Apellido Cliente',
				clientEmail:       'Correo Cliente',
				dateCreation:      'Fecha de Creación',
				dateProgram:       'Fecha Programada',
				details:           'Detalles',
				equipmentCode:     'Código del Equipo',
				equipmentName:     'Nombre del Equipo',
				status:            'Estado del Servicio',
				statusEquipment:   'Estado del Equipo',
				storageCode:       'Código de Almacenamiento',
				storageName:       'Nombre del Almacenamiento',
				technicalLastName: 'Apellido Técnico',
				technicalName:     'Nombre Técnico',
				workingDay:        'Jornada Trabajo',

			}
			
			export const UpdateService = Joi.object({ 
				_idService:		  Joi.string().required().label(JoiSchemaLabels._idService),
				_idTechnical:     Joi.string().hex().required().label(JoiSchemaLabels.technicalName),
				dateProgram:      Joi.string().allow('').required().label(JoiSchemaLabels.dateProgram),
				workingDay:       Joi.string().required().label(JoiSchemaLabels.workingDay),
				statusEquipment:  Joi.string().required().label(JoiSchemaLabels.statusEquipment),
				status: 		  Joi.string().required().label(JoiSchemaLabels.status),
			})
		}
	}
}